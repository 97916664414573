import { Input } from '@cocoonspace/ui-admin/components/input'
import { Label } from '@cocoonspace/ui-admin/components/label'
import { cn } from '@cocoonspace/ui-admin/lib/utils'
import { PlusIcon } from 'lucide-react'

export const StringFilter = ({
	label,
	name,
	value,
	onChange,
}: {
	label?: string
	name: string
	value: string
	onChange: (value: string | null) => void
}) => (
	<div className='flex flex-col gap-1'>
		<div className='relative'>
			<button
				type='button'
				className='absolute top-px bottom-px left-2 z-10'
				disabled={!value}
				onClick={(e) => {
					e.stopPropagation()
					onChange(null)
				}}
			>
				<PlusIcon
					className={cn('size-4 transition-transform', {
						'rotate-45': !!value,
					})}
				/>
			</button>

			<div
				className={cn(
					'flex h-9 w-full items-center gap-2 rounded-md border border-input bg-transparent px-3 py-1 text-sm transition-colors file:border-0 file:bg-transparent file:font-medium file:text-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
					{ 'border-dashed': !value },
				)}
			>
				{!!label && (
					<Label
						htmlFor={name}
						className='border-r pr-2 pl-5 hover:cursor-pointer'
					>
						{label}
					</Label>
				)}

				<Input
					id={name}
					name={name}
					className='border-none'
					value={value || ''}
					onChange={(e) => onChange(e.target.value || '')}
				/>
			</div>
		</div>
	</div>
)
