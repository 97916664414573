import { cn } from '@cocoonspace/ui-admin/lib/utils'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import { type VariantProps, cva } from 'class-variance-authority'
import * as React from 'react'

const Tabs = TabsPrimitive.Root

const TabsList = React.forwardRef<
	React.ElementRef<typeof TabsPrimitive.List>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
	<TabsPrimitive.List
		ref={ref}
		className={cn(
			'inline-flex items-center justify-center gap-2 overflow-x-auto rounded-lg bg-muted p-2 text-muted-foreground',
			className,
		)}
		{...props}
	/>
))

TabsList.displayName = TabsPrimitive.List.displayName

const tabsTriggerVariants = cva(
	'inline-flex items-center justify-center whitespace-nowrap px-4 py-1 font-medium text-sm disabled:opacity-50',
	{
		variants: {
			variant: {
				default: [
					'rounded-md ring-offset-background transition-all',
					'disabled:pointer-events-none data-[state=active]:bg-primary',
					'hover:bg-slate-200 data-[state=active]:text-primary-foreground data-[state=active]:shadow dark:hover:bg-slate-900',
					'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
				],
				tab: [
					'flex-1 rounded-t py-2',
					'data-[state=active]:bg-card data-[state=active]:text-bold data-[state=active]:text-card-foreground data-[state=active]:shadow-none',
				],
			},
		},
		defaultVariants: {
			variant: 'default',
		},
	},
)

const TabsTrigger = React.forwardRef<
	React.ElementRef<typeof TabsPrimitive.Trigger>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger> &
		VariantProps<typeof tabsTriggerVariants>
>(({ className, variant, ...props }, ref) => (
	<TabsPrimitive.Trigger
		ref={ref}
		className={tabsTriggerVariants({ variant, className })}
		{...props}
	/>
))

TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = React.forwardRef<
	React.ElementRef<typeof TabsPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
	<TabsPrimitive.Content
		ref={ref}
		className={cn(
			'ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
			className,
		)}
		{...props}
	/>
))

TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsContent, TabsList, TabsTrigger }
