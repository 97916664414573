/**
 * Generated by orval 🍺. Do not edit manually.
 */
import { useMutation, useQuery } from '@tanstack/react-query'
import type {
	DataTag,
	DefinedInitialDataOptions,
	DefinedUseQueryResult,
	MutationFunction,
	QueryFunction,
	QueryKey,
	UndefinedInitialDataOptions,
	UseMutationOptions,
	UseMutationResult,
	UseQueryOptions,
	UseQueryResult,
} from '@tanstack/react-query'
import type { CreateUpdateEntryBody } from '../../types/createUpdateEntryBody'
import type { CreateUpdateEntryParams } from '../../types/createUpdateEntryParams'
import type { EntriesResponse } from '../../types/entriesResponse'
import type { EntryResponse } from '../../types/entryResponse'
import type { ExecuteAction200 } from '../../types/executeAction200'
import type { ExecuteActionBody } from '../../types/executeActionBody'
import type { GetEntryParams } from '../../types/getEntryParams'
import type { ListEntityEntriesParams } from '../../types/listEntityEntriesParams'
import type { UnauthorizedResponse } from '../../types/unauthorizedResponse'
import type { UnexpectedResponse } from '../../types/unexpectedResponse'
import { useCustomInstance } from '../../axios-client/use-custom-instance'
import type {
	ErrorType,
	BodyType,
} from '../../axios-client/use-custom-instance'

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * Return the description of an entity.

 * @summary Get account info
 */
export const listEntityEntries = (
	describeId: string,
	params?: ListEntityEntriesParams,
	options?: SecondParameter<typeof useCustomInstance>,
	signal?: AbortSignal,
) => {
	return useCustomInstance<EntriesResponse>(
		{ url: `/_admin/${describeId}`, method: 'GET', params, signal },
		options,
	)
}

export const getListEntityEntriesQueryKey = (
	describeId: string,
	params?: ListEntityEntriesParams,
) => {
	return [`/_admin/${describeId}`, ...(params ? [params] : [])] as const
}

export const getListEntityEntriesQueryOptions = <
	TData = Awaited<ReturnType<typeof listEntityEntries>>,
	TError = ErrorType<UnauthorizedResponse | UnexpectedResponse>,
>(
	describeId: string,
	params?: ListEntityEntriesParams,
	options?: {
		query?: Partial<
			UseQueryOptions<
				Awaited<ReturnType<typeof listEntityEntries>>,
				TError,
				TData
			>
		>
		request?: SecondParameter<typeof useCustomInstance>
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {}

	const queryKey =
		queryOptions?.queryKey ?? getListEntityEntriesQueryKey(describeId, params)

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof listEntityEntries>>
	> = ({ signal }) =>
		listEntityEntries(describeId, params, requestOptions, signal)

	return {
		queryKey,
		queryFn,
		enabled: !!describeId,
		...queryOptions,
	} as UseQueryOptions<
		Awaited<ReturnType<typeof listEntityEntries>>,
		TError,
		TData
	> & { queryKey: DataTag<QueryKey, TData> }
}

export type ListEntityEntriesQueryResult = NonNullable<
	Awaited<ReturnType<typeof listEntityEntries>>
>
export type ListEntityEntriesQueryError = ErrorType<
	UnauthorizedResponse | UnexpectedResponse
>

export function useListEntityEntries<
	TData = Awaited<ReturnType<typeof listEntityEntries>>,
	TError = ErrorType<UnauthorizedResponse | UnexpectedResponse>,
>(
	describeId: string,
	params: undefined | ListEntityEntriesParams,
	options: {
		query: Partial<
			UseQueryOptions<
				Awaited<ReturnType<typeof listEntityEntries>>,
				TError,
				TData
			>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<typeof listEntityEntries>>,
					TError,
					TData
				>,
				'initialData'
			>
		request?: SecondParameter<typeof useCustomInstance>
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useListEntityEntries<
	TData = Awaited<ReturnType<typeof listEntityEntries>>,
	TError = ErrorType<UnauthorizedResponse | UnexpectedResponse>,
>(
	describeId: string,
	params?: ListEntityEntriesParams,
	options?: {
		query?: Partial<
			UseQueryOptions<
				Awaited<ReturnType<typeof listEntityEntries>>,
				TError,
				TData
			>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<typeof listEntityEntries>>,
					TError,
					TData
				>,
				'initialData'
			>
		request?: SecondParameter<typeof useCustomInstance>
	},
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useListEntityEntries<
	TData = Awaited<ReturnType<typeof listEntityEntries>>,
	TError = ErrorType<UnauthorizedResponse | UnexpectedResponse>,
>(
	describeId: string,
	params?: ListEntityEntriesParams,
	options?: {
		query?: Partial<
			UseQueryOptions<
				Awaited<ReturnType<typeof listEntityEntries>>,
				TError,
				TData
			>
		>
		request?: SecondParameter<typeof useCustomInstance>
	},
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Get account info
 */

export function useListEntityEntries<
	TData = Awaited<ReturnType<typeof listEntityEntries>>,
	TError = ErrorType<UnauthorizedResponse | UnexpectedResponse>,
>(
	describeId: string,
	params?: ListEntityEntriesParams,
	options?: {
		query?: Partial<
			UseQueryOptions<
				Awaited<ReturnType<typeof listEntityEntries>>,
				TError,
				TData
			>
		>
		request?: SecondParameter<typeof useCustomInstance>
	},
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
	const queryOptions = getListEntityEntriesQueryOptions(
		describeId,
		params,
		options,
	)

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: DataTag<QueryKey, TData>
	}

	query.queryKey = queryOptions.queryKey

	return query
}

/**
 * @summary Create or update an entry
 */
export const createUpdateEntry = (
	describeId: string,
	createUpdateEntryBody: BodyType<CreateUpdateEntryBody>,
	params?: CreateUpdateEntryParams,
	options?: SecondParameter<typeof useCustomInstance>,
	signal?: AbortSignal,
) => {
	return useCustomInstance<EntryResponse>(
		{
			url: `/_admin/${describeId}`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: createUpdateEntryBody,
			params,
			signal,
		},
		options,
	)
}

export const getCreateUpdateEntryMutationOptions = <
	TError = ErrorType<UnauthorizedResponse | UnexpectedResponse>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof createUpdateEntry>>,
		TError,
		{
			describeId: string
			data: BodyType<CreateUpdateEntryBody>
			params?: CreateUpdateEntryParams
		},
		TContext
	>
	request?: SecondParameter<typeof useCustomInstance>
}): UseMutationOptions<
	Awaited<ReturnType<typeof createUpdateEntry>>,
	TError,
	{
		describeId: string
		data: BodyType<CreateUpdateEntryBody>
		params?: CreateUpdateEntryParams
	},
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof createUpdateEntry>>,
		{
			describeId: string
			data: BodyType<CreateUpdateEntryBody>
			params?: CreateUpdateEntryParams
		}
	> = (props) => {
		const { describeId, data, params } = props ?? {}

		return createUpdateEntry(describeId, data, params, requestOptions)
	}

	return { mutationFn, ...mutationOptions }
}

export type CreateUpdateEntryMutationResult = NonNullable<
	Awaited<ReturnType<typeof createUpdateEntry>>
>
export type CreateUpdateEntryMutationBody = BodyType<CreateUpdateEntryBody>
export type CreateUpdateEntryMutationError = ErrorType<
	UnauthorizedResponse | UnexpectedResponse
>

/**
 * @summary Create or update an entry
 */
export const useCreateUpdateEntry = <
	TError = ErrorType<UnauthorizedResponse | UnexpectedResponse>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof createUpdateEntry>>,
		TError,
		{
			describeId: string
			data: BodyType<CreateUpdateEntryBody>
			params?: CreateUpdateEntryParams
		},
		TContext
	>
	request?: SecondParameter<typeof useCustomInstance>
}): UseMutationResult<
	Awaited<ReturnType<typeof createUpdateEntry>>,
	TError,
	{
		describeId: string
		data: BodyType<CreateUpdateEntryBody>
		params?: CreateUpdateEntryParams
	},
	TContext
> => {
	const mutationOptions = getCreateUpdateEntryMutationOptions(options)

	return useMutation(mutationOptions)
}
/**
 * @summary Infos for a specific entry
 */
export const getEntry = (
	describeId: string,
	entryId: string,
	params?: GetEntryParams,
	options?: SecondParameter<typeof useCustomInstance>,
	signal?: AbortSignal,
) => {
	return useCustomInstance<EntryResponse>(
		{ url: `/_admin/${describeId}/${entryId}`, method: 'GET', params, signal },
		options,
	)
}

export const getGetEntryQueryKey = (
	describeId: string,
	entryId: string,
	params?: GetEntryParams,
) => {
	return [
		`/_admin/${describeId}/${entryId}`,
		...(params ? [params] : []),
	] as const
}

export const getGetEntryQueryOptions = <
	TData = Awaited<ReturnType<typeof getEntry>>,
	TError = ErrorType<UnauthorizedResponse | UnexpectedResponse>,
>(
	describeId: string,
	entryId: string,
	params?: GetEntryParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<typeof getEntry>>, TError, TData>
		>
		request?: SecondParameter<typeof useCustomInstance>
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {}

	const queryKey =
		queryOptions?.queryKey ?? getGetEntryQueryKey(describeId, entryId, params)

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getEntry>>> = ({
		signal,
	}) => getEntry(describeId, entryId, params, requestOptions, signal)

	return {
		queryKey,
		queryFn,
		enabled: !!(describeId && entryId),
		...queryOptions,
	} as UseQueryOptions<Awaited<ReturnType<typeof getEntry>>, TError, TData> & {
		queryKey: DataTag<QueryKey, TData>
	}
}

export type GetEntryQueryResult = NonNullable<
	Awaited<ReturnType<typeof getEntry>>
>
export type GetEntryQueryError = ErrorType<
	UnauthorizedResponse | UnexpectedResponse
>

export function useGetEntry<
	TData = Awaited<ReturnType<typeof getEntry>>,
	TError = ErrorType<UnauthorizedResponse | UnexpectedResponse>,
>(
	describeId: string,
	entryId: string,
	params: undefined | GetEntryParams,
	options: {
		query: Partial<
			UseQueryOptions<Awaited<ReturnType<typeof getEntry>>, TError, TData>
		> &
			Pick<
				DefinedInitialDataOptions<
					Awaited<ReturnType<typeof getEntry>>,
					TError,
					TData
				>,
				'initialData'
			>
		request?: SecondParameter<typeof useCustomInstance>
	},
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetEntry<
	TData = Awaited<ReturnType<typeof getEntry>>,
	TError = ErrorType<UnauthorizedResponse | UnexpectedResponse>,
>(
	describeId: string,
	entryId: string,
	params?: GetEntryParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<typeof getEntry>>, TError, TData>
		> &
			Pick<
				UndefinedInitialDataOptions<
					Awaited<ReturnType<typeof getEntry>>,
					TError,
					TData
				>,
				'initialData'
			>
		request?: SecondParameter<typeof useCustomInstance>
	},
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetEntry<
	TData = Awaited<ReturnType<typeof getEntry>>,
	TError = ErrorType<UnauthorizedResponse | UnexpectedResponse>,
>(
	describeId: string,
	entryId: string,
	params?: GetEntryParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<typeof getEntry>>, TError, TData>
		>
		request?: SecondParameter<typeof useCustomInstance>
	},
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Infos for a specific entry
 */

export function useGetEntry<
	TData = Awaited<ReturnType<typeof getEntry>>,
	TError = ErrorType<UnauthorizedResponse | UnexpectedResponse>,
>(
	describeId: string,
	entryId: string,
	params?: GetEntryParams,
	options?: {
		query?: Partial<
			UseQueryOptions<Awaited<ReturnType<typeof getEntry>>, TError, TData>
		>
		request?: SecondParameter<typeof useCustomInstance>
	},
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
	const queryOptions = getGetEntryQueryOptions(
		describeId,
		entryId,
		params,
		options,
	)

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: DataTag<QueryKey, TData>
	}

	query.queryKey = queryOptions.queryKey

	return query
}

/**
 * @summary Delete an entry
 */
export const deleteEntry = (
	describeId: string,
	entryId: string,
	options?: SecondParameter<typeof useCustomInstance>,
) => {
	return useCustomInstance<void>(
		{ url: `/_admin/${describeId}/${entryId}`, method: 'DELETE' },
		options,
	)
}

export const getDeleteEntryMutationOptions = <
	TError = ErrorType<UnauthorizedResponse | UnexpectedResponse>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof deleteEntry>>,
		TError,
		{ describeId: string; entryId: string },
		TContext
	>
	request?: SecondParameter<typeof useCustomInstance>
}): UseMutationOptions<
	Awaited<ReturnType<typeof deleteEntry>>,
	TError,
	{ describeId: string; entryId: string },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof deleteEntry>>,
		{ describeId: string; entryId: string }
	> = (props) => {
		const { describeId, entryId } = props ?? {}

		return deleteEntry(describeId, entryId, requestOptions)
	}

	return { mutationFn, ...mutationOptions }
}

export type DeleteEntryMutationResult = NonNullable<
	Awaited<ReturnType<typeof deleteEntry>>
>

export type DeleteEntryMutationError = ErrorType<
	UnauthorizedResponse | UnexpectedResponse
>

/**
 * @summary Delete an entry
 */
export const useDeleteEntry = <
	TError = ErrorType<UnauthorizedResponse | UnexpectedResponse>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof deleteEntry>>,
		TError,
		{ describeId: string; entryId: string },
		TContext
	>
	request?: SecondParameter<typeof useCustomInstance>
}): UseMutationResult<
	Awaited<ReturnType<typeof deleteEntry>>,
	TError,
	{ describeId: string; entryId: string },
	TContext
> => {
	const mutationOptions = getDeleteEntryMutationOptions(options)

	return useMutation(mutationOptions)
}
/**
 * @summary Execute an action on an entry
 */
export const executeAction = (
	describeId: string,
	entryId: string,
	action: string,
	executeActionBody?: BodyType<ExecuteActionBody>,
	options?: SecondParameter<typeof useCustomInstance>,
	signal?: AbortSignal,
) => {
	return useCustomInstance<ExecuteAction200>(
		{
			url: `/_admin/${describeId}/${entryId}/${action}`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: executeActionBody,
			signal,
		},
		options,
	)
}

export const getExecuteActionMutationOptions = <
	TError = ErrorType<UnauthorizedResponse | UnexpectedResponse>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof executeAction>>,
		TError,
		{
			describeId: string
			entryId: string
			action: string
			data?: BodyType<ExecuteActionBody>
		},
		TContext
	>
	request?: SecondParameter<typeof useCustomInstance>
}): UseMutationOptions<
	Awaited<ReturnType<typeof executeAction>>,
	TError,
	{
		describeId: string
		entryId: string
		action: string
		data?: BodyType<ExecuteActionBody>
	},
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof executeAction>>,
		{
			describeId: string
			entryId: string
			action: string
			data?: BodyType<ExecuteActionBody>
		}
	> = (props) => {
		const { describeId, entryId, action, data } = props ?? {}

		return executeAction(describeId, entryId, action, data, requestOptions)
	}

	return { mutationFn, ...mutationOptions }
}

export type ExecuteActionMutationResult = NonNullable<
	Awaited<ReturnType<typeof executeAction>>
>
export type ExecuteActionMutationBody = BodyType<ExecuteActionBody>
export type ExecuteActionMutationError = ErrorType<
	UnauthorizedResponse | UnexpectedResponse
>

/**
 * @summary Execute an action on an entry
 */
export const useExecuteAction = <
	TError = ErrorType<UnauthorizedResponse | UnexpectedResponse>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof executeAction>>,
		TError,
		{
			describeId: string
			entryId: string
			action: string
			data?: BodyType<ExecuteActionBody>
		},
		TContext
	>
	request?: SecondParameter<typeof useCustomInstance>
}): UseMutationResult<
	Awaited<ReturnType<typeof executeAction>>,
	TError,
	{
		describeId: string
		entryId: string
		action: string
		data?: BodyType<ExecuteActionBody>
	},
	TContext
> => {
	const mutationOptions = getExecuteActionMutationOptions(options)

	return useMutation(mutationOptions)
}
