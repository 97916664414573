import { Badge } from '@cocoonspace/ui-admin/components/badge'
import { Button } from '@cocoonspace/ui-admin/components/button'
import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from '@cocoonspace/ui-admin/components/dropdown-menu'
import { Label } from '@cocoonspace/ui-admin/components/label'
import { cn } from '@cocoonspace/ui-admin/lib/utils'
import { ChevronsUpDownIcon, PlusIcon } from 'lucide-react'
import { useMemo } from 'react'
import type { EntityName } from '~/config/entities'
import { useListEntityEntries } from '~/lib/admin-sdk-js/domains/entries/entries'

export const EntitySelectMultiFilter = ({
	label,
	name,
	value,
	entity,
	filters,
	onChange,
}: {
	label: string
	name: string
	value: string[]
	entity: EntityName
	filters?: Record<string, string | boolean>
	onChange: (value: string[] | null) => void
}) => {
	const { data: rows } = useListEntityEntries(entity, filters)

	const entitiesByIds = useMemo(() => {
		return rows?.data?.reduce((acc, option) => {
			acc[option.id] = option
			return acc
		}, {})
	}, [rows])

	return (
		<div className='flex flex-col gap-1'>
			<DropdownMenu>
				<div className='relative flex items-center gap-2'>
					<button
						type='button'
						className='absolute top-px bottom-px left-2 z-10'
						disabled={!value?.length}
						onClick={(e) => {
							e.stopPropagation()
							onChange(null)
						}}
					>
						<PlusIcon
							className={cn('size-4 transition-transform', {
								'rotate-45': !!value?.length,
							})}
						/>
					</button>

					<DropdownMenuTrigger className='flex min-w-[250px] max-w-[300px] flex-row items-center gap-2'>
						<Button
							variant='outline'
							role='combobox'
							className={cn('!px-2 w-full justify-between', {
								'border-dashed': !value?.length,
							})}
						>
							{!!label && (
								<Label
									htmlFor={name}
									className='border-r pr-2 pl-5 hover:cursor-pointer'
								>
									{label}
								</Label>
							)}

							<div className='flex flex-1 flex-row gap-2 truncate'>
								{value?.map((item) => (
									<Badge
										key={item}
										variant='outline'
									>
										{entitiesByIds?.[item]?.title || item}
									</Badge>
								))}
							</div>

							{!value?.length && (
								<ChevronsUpDownIcon className='ml-auto size-4 w-3 opacity-50' />
							)}
						</Button>
					</DropdownMenuTrigger>
				</div>

				<DropdownMenuContent className='max-h-[300px] overflow-y-auto'>
					{rows?.data?.map((option) => (
						<DropdownMenuCheckboxItem
							key={option.id}
							checked={value?.includes(option.id)}
							onCheckedChange={() =>
								onChange(
									value?.includes(option.id)
										? value.filter((id) => id !== option.id)
										: [...(value || []), option.id],
								)
							}
							className='gap-2'
						>
							{option.title || option.id || ''}
						</DropdownMenuCheckboxItem>
					))}
				</DropdownMenuContent>
			</DropdownMenu>
		</div>
	)
}
