import { dateTimeUtils } from '@cocoonspace/shared/utils/date-time-utils'
import { Button } from '@cocoonspace/ui-admin/components/button'
import { Calendar } from '@cocoonspace/ui-admin/components/calendar'
import { Card } from '@cocoonspace/ui-admin/components/card'
import { Checkbox } from '@cocoonspace/ui-admin/components/checkbox'
import { Form } from '@cocoonspace/ui-admin/components/form'
import { FormControlledInput } from '@cocoonspace/ui-admin/components/form-controlled-input'
import { cn } from '@cocoonspace/ui-admin/lib/utils'
import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'next-i18next'
import { Controller, useForm } from 'react-hook-form'
import z from 'zod'
import EditorTime from '~/pages/[describeId]/_components/atoms/attribute-editor/editor-time'
import type { DateTimeSlot } from '../../../_types/date-time-slot'

type FormValues = z.infer<typeof formValuesSchema>

const formValuesSchema = z.object({
	timeslot: z
		.object({
			day: z.string(),
			start: z.number(),
			end: z.number(),
		})
		.refine((data) => data.end > data.start, {
			message: 'errors:forms.endTimeMustBeHigherThanStartTime',
			path: ['end'],
		}),
	recurence: z.object({
		isActive: z.boolean(),
		type: z.enum(['day', 'week', 'month']),
		amountUnit: z.number(),
		amountRecur: z.number(),
	}),
})

const RecurenceRepeatPicker = ({
	value,
	onChange,
}: {
	value: FormValues['recurence']
	onChange: (value: FormValues['recurence']) => void
}) => {
	const { t } = useTranslation()

	return (
		<div className='w-full text-left'>
			{/* biome-ignore lint/a11y/noLabelWithoutControl: <explanation> */}
			<label className='mb-2 inline-flex flex-row items-center gap-1 text-sm'>
				<Checkbox
					checked={value.isActive}
					data-testid='is-active'
					onCheckedChange={() =>
						onChange({ ...value, isActive: !value.isActive })
					}
				/>
				Répéter
			</label>

			<Card
				className={cn('border p-2 pl-4', {
					'opacity-40': !value.isActive,
				})}
			>
				<div className='flex flex-row items-center gap-2'>
					<label className='flex flex-row items-center gap-2 text-sm'>
						Tou(te)s les :
						<input
							type='number'
							min={1}
							value={value.amountUnit}
							className='w-16 rounded border bg-card p-1 pl-4 text-center'
							disabled={!value.isActive}
							data-testid='amount-unit'
							onChange={(e) =>
								onChange({ ...value, amountUnit: Number(e.target.value) })
							}
						/>
					</label>

					<div className='ml-auto grid grid-cols-3 overflow-hidden rounded border bg-card'>
						{(
							['day', 'week', 'month'] as FormValues['recurence']['type'][]
						).map((type) => (
							<button
								key={type}
								type='button'
								disabled={!value.isActive}
								className={cn('w-8 p-1 uppercase', {
									'bg-primary text-white': type === value.type,
								})}
								data-testid={`type-${type}`}
								onClick={() => onChange({ ...value, type })}
							>
								{t(`common:${type}Short`)}
							</button>
						))}
					</div>
				</div>

				<label className='flex flex-row items-center gap-2 text-sm'>
					Occurences :
					<input
						type='number'
						value={value.amountRecur}
						min={1}
						disabled={!value.isActive}
						className='w-16 rounded border bg-card p-1 pl-4 text-center'
						data-testid='amount-recur'
						onChange={(e) =>
							onChange({ ...value, amountRecur: Number(e.target.value) })
						}
					/>
				</label>
			</Card>
		</div>
	)
}

export const RecurencePicker = ({
	onChange,
}: {
	onChange: (value: DateTimeSlot[]) => void
}) => {
	const { t } = useTranslation()

	const form = useForm<FormValues>({
		resolver: zodResolver(formValuesSchema),
		defaultValues: {
			timeslot: {
				day: dateTimeUtils().customFormat('dateTech'),
				start: 800,
				end: 2000,
			},
			recurence: {
				isActive: false,
				type: 'day',
				amountUnit: 1,
				amountRecur: 1,
			},
		},
	})

	const onSubmit = (formValues: FormValues) => {
		const { convertToStringTime } = dateTimeUtils.utils

		const start = convertToStringTime(formValues.timeslot.start)
		const end = convertToStringTime(formValues.timeslot.end)

		const timeslots = Array.from({ length: formValues.recurence.amountRecur })
			.filter((_, i) => formValues.recurence.isActive || i === 0)
			.map((_, i) => ({
				start,
				end,
				day: dateTimeUtils(formValues.timeslot.day)
					.add(formValues.recurence.amountUnit * i, formValues.recurence.type)
					.format('YYYY-MM-DD'),
			}))

		onChange(timeslots)
	}

	return (
		<Form {...form}>
			<form
				data-testid='form'
				noValidate
				onSubmit={form.handleSubmit(onSubmit)}
			>
				<div className='flex flex-row items-center gap-8'>
					<div>
						<Controller
							name='timeslot.day'
							control={form.control}
							render={({ field }) => (
								<Calendar
									mode='single'
									selected={dateTimeUtils(field.value).toDate()}
									data-testid='day-picker'
									onSelect={(value) =>
										field.onChange(
											dateTimeUtils(value).customFormat('dateTech'),
										)
									}
								/>
							)}
						/>
					</div>

					<div className='flex flex-col items-center gap-4'>
						<div className='grid w-full grid-cols-2 gap-4'>
							<FormControlledInput
								name='timeslot.start'
								render={({ field }) => (
									<EditorTime
										name='timeslot.start'
										labelKey='start'
										attribute={{
											name: 'timeslot.start',
											type: 'time',
											editable: true,
										}}
										value={field.value}
										onChange={(time) => field.onChange(time)}
										onBlur={() => {}}
									/>
								)}
							/>

							<FormControlledInput
								name='timeslot.end'
								render={({ field }) => (
									<EditorTime
										name='timeslot.end'
										labelKey='end'
										attribute={{
											name: 'timeslot.end',
											type: 'time',
											editable: true,
										}}
										value={field.value}
										onChange={(time) => field.onChange(time)}
										onBlur={() => {}}
									/>
								)}
							/>
						</div>

						<Controller
							name='recurence'
							control={form.control}
							render={({ field }) => (
								<RecurenceRepeatPicker
									value={field.value}
									onChange={field.onChange}
								/>
							)}
						/>

						<Button
							type='submit'
							className='w-full'
						>
							{t('actions:add.btnLabel')}
						</Button>
					</div>
				</div>
			</form>
		</Form>
	)
}
