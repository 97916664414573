import type { Event } from '@cocoonspace/sdk-js/types/event'
import { capitalize } from '@cocoonspace/shared/utils/capitalize'
import { dateTimeUtils } from '@cocoonspace/shared/utils/date-time-utils'
import { cn } from '@cocoonspace/ui-admin/lib/utils'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { DescribeIcon } from '~/components/describe-icon'

export const EventCellRender = ({
	event,
	className,
	titleClassName,
	slotClassName,
}: {
	event: Pick<
		Event,
		'day' | 'start' | 'end' | 'space' | 'start_local' | 'end_local'
	>
	className?: string
	titleClassName?: string
	slotClassName?: string
}) => {
	const { t } = useTranslation()

	const eventTitle = useMemo(() => {
		if (!event.start_local || !event.end_local) return ' '

		const startDate = dateTimeUtils(event.start_local)
		const date = capitalize(startDate.customFormat('shortDayLongMonthYear'))

		const fromTo = t('common:fromTo', {
			start: startDate.customFormat('hours'),
			end: dateTimeUtils(event.end_local).customFormat('hours'),
		})

		return `${date} ${fromTo}`
	}, [event, t])

	return (
		<div className={cn('flex flex-col gap-1 truncate', className)}>
			<div
				className={cn(
					'truncate font-bold text-lg leading-none',
					titleClassName,
				)}
			>
				{event.space?.title}
			</div>

			<div
				className={cn(
					'flex flex-row items-center gap-1 text-sm',
					slotClassName,
				)}
			>
				<DescribeIcon
					describeId='events'
					size={16}
					className='shrink-0'
				/>

				<div className='mt-[2px] leading-none'>{eventTitle}</div>
			</div>
		</div>
	)
}
