import { Label } from '@cocoonspace/ui-admin/components/label'
import { useTranslation } from 'next-i18next'
import { EntitySelectMultiFilter } from '~/components/filters/entity-select-multi-filter'
import { SelectFilter } from '~/components/filters/select-filter'
import { StringFilter } from '~/components/filters/string-filter'
import { ServicesFilter } from '../molecules/filters/services-filter'

export const TableFilters = ({
	value,
	onChange,
}: {
	value: Record<string, any>
	onChange: (value: Record<string, any>) => void
}) => {
	const { t } = useTranslation()

	return (
		<div className='flex items-end gap-2'>
			<SelectFilter
				name='zone'
				label={t('attributesLabels:zone')}
				options={[
					{ label: 'Paris', value: 'paris' },
					{ label: 'Lyon', value: 'lyon' },
				]}
				value={value.zone}
				onChange={(zone) => onChange({ ...value, zone })}
			/>

			<EntitySelectMultiFilter
				entity='venues'
				name='venues'
				label={t('attributesLabels:venues')}
				value={value.venues}
				filters={{ excludeExtras: true }}
				onChange={(venues) => onChange({ ...value, venues })}
			/>

			<StringFilter
				name='title'
				label={t('attributesLabels:title')}
				value={value.title}
				onChange={(title) => onChange({ ...value, title })}
			/>

			<SelectFilter
				name='capacity'
				label={t('attributesLabels:capacity')}
				options={[
					{ label: '1-4', value: '1-4' },
					{ label: '4-8', value: '4-8' },
					{ label: '8-12', value: '8-12' },
					{ label: '12-16', value: '12-16' },
					{ label: '16+', value: '16-' },
				]}
				value={value.capacity}
				onChange={(capacity) => onChange({ ...value, capacity })}
			/>

			<div className='flex w-[250px] flex-col gap-1'>
				<Label>{t('attributesLabels:features')}</Label>

				<ServicesFilter
					value={value.features}
					className='w-[250px]'
					onChange={(features) => onChange({ ...value, features })}
				/>
			</div>
		</div>
	)
}
