import { Label } from '@cocoonspace/ui-admin/components/label'
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@cocoonspace/ui-admin/components/select'
import { cn } from '@cocoonspace/ui-admin/lib/utils'
import { PlusIcon } from 'lucide-react'
import { useTranslation } from 'next-i18next'

export const SelectFilter = <T extends string>({
	label,
	name,
	value,
	options,
	onChange,
}: {
	label: string
	name: string
	value: string
	options: ({ value: T; label?: string } | T)[]
	onChange: (value: T | null) => void
}) => {
	const { t } = useTranslation()

	return (
		<div className='flex flex-col gap-1'>
			<Select
				value={value || ''}
				onValueChange={(value) => onChange(value as T)}
			>
				<div className='relative flex items-center gap-2'>
					<button
						type='button'
						className='absolute top-px bottom-px left-2 z-10'
						disabled={!value}
						onClick={(e) => {
							e.stopPropagation()
							onChange(null)
						}}
					>
						<PlusIcon
							className={cn('size-4 transition-transform', {
								'rotate-45': !!value,
							})}
						/>
					</button>

					<SelectTrigger
						className={cn(
							'!px-2 flex min-w-[150px] flex-row items-center gap-2',
							{ 'border-dashed': !value },
						)}
					>
						{!!label && (
							<Label
								htmlFor={name}
								className='border-r pr-2 pl-5 hover:cursor-pointer'
							>
								{label}
							</Label>
						)}

						<SelectValue />
					</SelectTrigger>
				</div>

				<SelectContent>
					{options?.map((option, i) => {
						if (typeof option === 'string') {
							return (
								<SelectItem
									key={i}
									value={option}
								>
									{t(`values:values.${name}.${option}`)}
								</SelectItem>
							)
						}

						return (
							<SelectItem
								key={i}
								value={option.value}
							>
								{option.label || option.value}
							</SelectItem>
						)
					})}
				</SelectContent>
			</Select>
		</div>
	)
}
